export default {
  heading: 'Playfair Display, sans-serif',
  subheading: 'Noto Sans Display',
  body: 'Montserrat, sans-serif',
  body2: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Cabin Sketch, cursive',
  googleFonts: [
    'Playfair Display:400,500,600,700',
    'Cabin Sketch',
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Noto Sans Display:100,200,300,400,500,600,700,800,900',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Comfortaa'
  ]
  // // heading: 'Cinzel, serif',
  // heading: 'Dancing Script, cursive',

  // body: 'Cinzel, serif',
  // monospace: 'Menlo, monospace',
  // display: 'Dancing Script, serif',
  // googleFonts: [
  //   'Cinzel:100,200,300,400,500,600,700,800,900',
  //   'Hahmlet:100,200,300,400,500,600,700,800,900',
  //   'Dancing Script:400,500,600,700',
  // ],
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
