export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      padding: ['', '', '', '3rem 2rem'],
      backgroundColor: 'white',
      color: 'black',
      padding: '0rem',
      // borderBottom: 'solid 1px black',
      '.hamburger': {
        right: '1rem',
        '> div': {
          backgroundColor: 'black'
        }
      },
      '.smallNavMenu': {
        '> div': {
          border: 'none',
          borderBottom: '2px white solid',
          a: {
            fontSize: '1rem',
            color: 'black',

            fontFamily: 'body'
          }
        }
      }
    },

    '.containerScrolled': {
      backgroundColor: 'white',
      color: 'black',
      padding: '0rem',
      // borderBottom: 'solid 1px black',
      '.smallNavMenu': {
        '> div': {
          borderColor: 'black',
          border: 'none',
          a: {
            fontFamily: 'body',
            color: 'black'
          }
        }
      },
      '.hamburger': {
        '> div': {
          backgroundColor: 'black'
        }
      }
    },
    '.navItem': {
      a: {
        fontFamily: 'body'
      }
    },

    '.logo': {
      // filter: 'brightness(0) invert(1)',
      img: {
        maxHeight: '75px'
      }
    },
    '.logoScrolled': {
      // filter: 'brightness(0)',
      img: {
        maxHeight: '60px'
      }
    },
    '.navMenuLogo': {
      // variant: 'customVariants.borderCorners',
      padding: '3rem 0rem',
      img: {
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.navMenuOpen': {
      color: 'black',
      backgroundColor: 'white'
    },
    '.socialContainer': {
      svg: {
        path: {
          fill: 'black'
        }
      }
    }
  },

  footer: {
    backgroundColor: 'white',
    borderTop: 'solid 2px',
    borderColor: 'primary',
    poadding: ['1rem 1rem 4rem', '', '', '1rem 1rem'],
    '.row': {
      '.columnLogo': {
        width: ['100%', '', '', '20%'],
        margin: 'unset',
        minWidth: '300px',
        padding: '2rem',
        '.logo': {
          // filter: 'brightness(0) invert(1)',
        }
      },

      '.columnNav': {
        flexGrow: '1',
        '.multiButtonContainer': {
          flexDirection: 'row',
          a: {
            variant: 'buttons.secondary',
            margin: '0.5rem'
          }
        }
      },
      '.columnSocial': {
        svg: {
          path: {
            fill: 'black'
          }
        }
      }
    },

    '.bottomBar': {
      padding: ['0rem', '', '', '0rem 3rem'],
      '.gonationLogo': {
        width: 'fit-content'
      }
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.25rem', '2.5rem', '2.75rem', '3rem'],
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1rem',
    textTransform: 'uppercase',
    fontFamily: 'heading'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1rem',
    border: 'none',
    fontFamily: 'subheading',
    opacity: '0.8',
    fontWeight: '200'
  },
  text: {
    lineHeight: '1.75',
    p: {
      lineHeight: '1.75',
      fontSize: 'inherit',
      textAlign: 'left'
    }
  },

  sideBySide1: {
    padding: ['1rem', '', '2rem', '3rem'],
    '.lazyload-wrapper': {
      minHeight: ['', '', '60vh'],
      maxHeight: ['', '', '80vh'],
      justifyContent: 'flex-start'
    },

    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      padding: ['2rem 1rem', '', '2rem 1rem 2rem 3rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  // sideBySide1: {
  //   padding: '0.5rem 0.75rem 1rem 1rem',
  //   '.lazyload-wrapper': {
  //     maxHeight: '75vh',
  //     justifyContent: 'flex-end',
  //   },
  //   '.content': {
  //     position: 'relative',
  //     alignItems: 'flex-start',
  //     margin: ['1rem 0rem', '', '0rem'],
  //     borderLeft: '8px solid',
  //     borderColor: 'primary',
  //   },
  //   '.title': {
  //     variant: 'customVariants.title',
  //   },
  //   '.subtitle': { variant: 'customVariants.subtitle' },
  //   '.text': {
  //     variant: 'customVariants.text',
  //   },
  //   a: {
  //     order: '5',
  //   },
  //   '.ctaButton': {
  //     variant: 'buttons.secondary',
  //   },
  // },

  // sideBySide2: {
  //   variant: 'customVariants.sideBySide1',
  //   padding: '0.5rem 1rem 1rem 0.75rem',
  //   '.lazyload-wrapper': {
  //     maxHeight: '75vh',
  //     justifyContent: 'flex-start',
  //   },
  //   '.imageContainer': {
  //     height: ['', '', '75vh'],
  //   },
  //   '.content': {
  //     borderRight: '8px solid',
  //     borderColor: 'primary',
  //     order: '2',
  //     position: 'relative',
  //     alignItems: 'flex-start',
  //     margin: ['1rem 0rem', '', '0rem'],
  //   },
  //   a: {
  //     order: '5',
  //   },
  // },

  homepageBlock: {
    marginTop: '1rem',
    color: 'white',
    '.section': {
      margin: 'auto auto',
      padding: '0.5rem',
      color: 'inherit'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'inherit'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      color: 'inherit'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'inherit',
      textAlign: 'center',
      p: {
        color: 'inherit',
        textAlign: 'center'
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    backgroundColor: '#f1f1f1',
    height: 'calc(100vh)',
    width: 'calc(100%)',
    position: 'relative',
    overflow: 'hidden',
    '.slick-slider': {
      order: ['', '', '', '2'],
      width: ['', '', '', '65%', '70%'],
      '.slick-initialized .slick-slide > div': {
        height: 'calc(100vh - 2rem)'
      },
      '.slick-arrow': {
        backgroundColor: 'primary',
        color: 'white',
        padding: '0.5rem',
        height: '40px',
        width: '40px',
        opacity: '0.8',
        left: '1rem',
        top: 'unset',
        bottom: '0rem'
      },
      '.slick-next': {
        left: '4rem',

        right: 'unset'
      },
      '.slick-slide': {
        img: {
          filter: 'brightness(0.9)'
        }
      },
      '.slick-dots': {
        bottom: ['3rem', '', '', '2rem'],
        left: '50%',
        transform: 'translateX(-50%)',
        li: {
          button: {
            '::before': {
              color: 'primary',
              fontSize: '1rem'
            }
          }
        }
      }
    },

    '.hero_content_container': {
      // backgroundColor: '#ffffff85',
      // height: '100%',
      // marginLeft: ['7.5%'],
      // maxWidth: '400px',
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'flex-start',
      // textAlign: 'left',
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      order: '2'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      order: '1'
    },
    '.text': {
      variant: 'customVariants.text',

      order: '3',

      p: {}
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageHeroShout: {
    display: 'none !important'
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    marginTop: '0rem'
  },

  homepagePersonailized: {
    background: 'none',
    padding: ['2rem 3rem 2rem', '3rem', '1rem 4rem'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh',
    // marginBottom: '10vh',
    '.title': {
      variant: 'customVariants.title',
      marginBottom: '1.5rem',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      marginBottom: '1.5rem',
      textAlign: 'center',
      fontSize: ['1.25rem', '1.5rem', '1.5rem', '1.5rem']
    },

    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      p: {
        letterSpacing: '1px',
        lineHeight: '1.7',
        textAlign: 'center'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },

    '::before': {
      content: "''",
      height: '80px',
      width: '2px',
      backgroundColor: 'black',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '3rem 2rem'
    },
    '::after': {
      content: "''",
      height: '80px',
      width: '2px',
      backgroundColor: 'black',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '3rem 2rem'
    }
  },

  homepageCatering: {
    variant: 'customVariants.homepageBlock'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageBlog: {
    variant: 'customVariants.sideBySide1'
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1'
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem 1rem 1rem', '', '', '0rem 0.5rem 1rem'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: 'secondary',
      fontFamily: 'body'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['#584c00', '', '', '#584c00'],
      color: '#e5d5c1'
    },
    '.date': {
      backgroundColor: '#202020',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer'
    },

    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageTestimonials: {
    padding: ['2rem 1rem', '', '4rem 1rem'],
    maxWidth: 'unset',
    margin: '0rem 0rem 1rem',
    // backgroundColor: 'secondary',
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      margin: '0 auto 1rem',
      width: '100%',
      display: 'inline-block'
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem'
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center'
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem']
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'primary',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem'
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    }
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1'
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1'
  },
  venueBar: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    marginTop: '90px',
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      color: 'text'
    },
    '.text': {
      color: 'text'
    },
    backgroundPosition: 'center center',
    color: 'text',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'text',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'text',
      '::placeholder': {
        color: 'text'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ========================
  // ? =======  blog   ========
  // ? ========================
  blogMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.box': {
      backgroundColor: 'lightgrey'
    }
  },

  blogPage: {},

  // ? ========================
  // ? =====  services   ======
  // ? ========================

  servicesMenu: {
    marginTop: '90px',
    '.menuSectionTitle': {
      display: 'none'
    },
    '.menuSectionDescription': {
      padding: '2rem',
      backgroundColor: 'secondary',
      margin: '0rem 0rem 2rem',
      color: 'black'
    },
    '.menuItemsContainer': {
      justifyContent: 'center'
    },
    '.menuItemContainer, .menuItemContainerImgActive': {
      width: ['100%', 'calc(50% - 1rem)', '', 'calc(33.3% - 1rem)'],
      margin: ['', '0.5rem', '', '0.5rem']
    },
    '.menuItemImageContainer': {
      display: 'block',
      padding: '0.25rem',
      border: 'solid 1px',
      borderColor: 'primary',
      borderRadius: '0px',
      img: {
        padding: '0.25rem'
      }
    },
    '.itemImageFill': {
      paddingBottom: '100%'
    },
    '.menuItemContentContainer': {
      zIndex: '1',
      '::before': {
        content: "''",
        border: 'solid 1px',
        borderColor: 'grey',
        width: 'calc(100% + 0.5rem)',
        height: 'calc(100% + 0.5rem)',
        position: 'absolute',
        left: '-0.25rem',
        top: '-0.25rem',
        zIndex: '-1'
      },
      position: 'relative',
      top: '-3rem',
      padding: '1rem',
      backgroundColor: 'grey',
      width: '90%',
      textAlign: 'center',
      margin: '0rem auto',
      backgroundColor: 'rgba(250, 248, 247, 0.9)',
      padding: '0.5rem'
    },
    '.menuItemName': {
      borderBottom: 'dashed 1px',
      paddingBottom: '0.5rem'
    },

    '.menuItemDescription': {
      fontSize: '0.8rem',
      width: '100%',
      textTransform: 'capitalize'
    }
  }
}
